import React, { useState, useEffect } from 'react'
import SearchComponent from './index'

const SearchPopover = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openSearch = () => {
    setIsOpen(true)
  }

  const closeSearch = () => {
    setIsOpen(false)
  }

  // Handle keyboard shortcut (Cmd+K / Ctrl+K)
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Cmd+K (Mac) or Ctrl+K (Windows/Linux)
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault() // Prevent default browser behavior
        setIsOpen(prevState => !prevState) // Toggle search open/close
      }
      
      // Handle Escape key to close search
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen])

  return (
    <div className="relative">
      {/* Search icon button */}
      <button
        onClick={openSearch}
        className="flex items-center justify-center p-2 text-gray-600 hover:text-blue-500 focus:outline-none"
        aria-label="Search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </button>

      {/* Search popover */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-start justify-center pt-20 overflow-auto">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-xl mx-4 mb-20">
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Search <span className="text-sm text-gray-500 ml-2">(⌘K / Ctrl+K)</span></h3>
                <button
                  onClick={closeSearch}
                  className="text-gray-400 hover:text-gray-500"
                  aria-label="Close search"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="relative w-full">
                <SearchComponent isPopover={true} onClose={closeSearch} className="w-full" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchPopover