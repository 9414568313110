import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/footer_logo.webp'
import FBIcon from '../../../components/Icons/FBIcon'
import XIcon from '../../../components/Icons/XIcon'
import YTIcon from '../../../components/Icons/YTIcon'
import StyleBar from '../../../components/StyleBar'

export default function Footer() {
  const colData = [
    {
      id: 1,
      title: 'LINKLY',
      column: [
        { url: '/#linkly-features', label: 'Features' },
        { url: '/pricing', label: 'Pricing' },
        { url: '/click-tracking-software', label: 'Competitors' },
        {
          url: 'https://linkly.getrewardful.com/signup',
          label: 'Affiliate Program',
        },
        { url: 'https://status.linklyhq.com/', label: 'Status & Uptime' },
        { url: '/contact', label: 'Contact' },
      ],
    },
    {
      id: 2,
      title: 'Guides',
      column: [
        { url: '/create-tracking-links', label: 'Tracking Links' },
        { url: '/support/link-rotator', label: 'Link Rotators' },
        { url: '/support/create-expiring-links', label: 'Expiring Links' },
        { url: '/support/custom-domain', label: 'Branded Shortlinks' },
        { url: '/blog', label: 'Blog' },
        { url: '/release-notes', label: 'Release Notes' },
      ],
    },
    {
      id: 3,
      title: 'Integrations',
      column: [
        {
          url: '/support/google-chrome-extension',
          label: 'Linkly for Chrome & Firefox',
        },
        {
          url: '/support/google-sheets-addon',
          label: 'Linkly for Google Sheets',
        },
        { url: '/support/zapier-integration', label: 'Linkly for Zapier' },
        { url: '/support/ga4-tracking-tags', label: 'Google Analytics 4' },
        {
          url: '/support/google-tag-manager-on-links',
          label: 'Google Tag Manager',
        },
        { url: '/support/api', label: 'API' },
      ],
    },
    {
      id: 4,
      title: 'Legal',
      column: [
        { url: '/support/terms', label: 'Terms of Use' },
        { url: '/support/privacy', label: 'Privacy Policy' },
        { url: '/support/gdpr', label: 'GDPR' },
      ],
    },
  ]

  return (
    <div className="mt-28 mb-3">
      <StyleBar heightClass="h-1" />

      <div className="container mx-auto">
        <div className="w-11/12 lg:w-9/12 m-auto">
          <div className="flex justify-between flex-wrap pt-12">
            {colData.map((col, key) => (
              <div key={key} className="mb-5 w-5/12 sm:w-auto">
                <h2 className="inter600 text-[#3E3E59]">{col.title}</h2>
                <ul className="flex flex-col gap-2 mt-2">
                  {col.column.map((link, index) => (
                    <li
                      key={index}
                      className="inter400 text-[#3e3e59] text-[12px] land:text-[14px] text-wrap hover:text-blue-700"
                    >
                      <Link to={link.url}>{link.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="flex items-center gap-2 justify-between mt-12">
            <Link to="/">
              <img
                className="h-auto max-w-full"
                src={logo}
                alt="linkly"
                loading="lazy"
              />
            </Link>
            <p className="text-[10px] text-[#3e3e59]">
              © Linkly All rights reserved.
            </p>
            <div className="flex items-center gap-3">
              <a
                href="https://www.facebook.com/linklyhq/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                className="hover:text-[#3E3E59]"
              >
                <FBIcon />
              </a>
              <a
                href="https://twitter.com/LinklyHQ"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <XIcon />
              </a>
              <a
                href="https://www.youtube.com/@linkly6915"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <YTIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
