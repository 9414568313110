import React, { useState, useEffect, useRef, useCallback } from 'react'
import lunr from 'lunr'
import { Link } from 'gatsby-link'

// Create cache for search index and documents map to share between instances
const searchCache = {
  indexPromise: null,
  documentsMapPromise: null,
  index: null,
  documentsMap: null
};

const SearchComponent = ({ className, isPopover = false, onClose = () => {} }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [localIndex, setLocalIndex] = useState(searchCache.index)
  const [localDocumentsMap, setLocalDocumentsMap] = useState(searchCache.documentsMap)
  const [showDropdown, setShowDropdown] = useState(false)
  const searchRef = useRef(null)
  const unmountedRef = useRef(false);

  const loadSearchData = useCallback(() => {
    // If we already have the data cached, use it
    if (searchCache.index && searchCache.documentsMap) {
      setLocalIndex(searchCache.index);
      setLocalDocumentsMap(searchCache.documentsMap);
      return;
    }
    
    // Load search index - use cached promise if it exists
    if (!searchCache.indexPromise) {
      searchCache.indexPromise = fetch('/search_index.json')
        .then(response => response.json())
        .then(indexData => {
          const loadedIndex = lunr.Index.load(indexData);
          searchCache.index = loadedIndex; // Cache the index
          return loadedIndex;
        });
    }
    
    // Load documents map - use cached promise if it exists
    if (!searchCache.documentsMapPromise) {
      searchCache.documentsMapPromise = fetch('/documents_map.json')
        .then(response => response.json())
        .then(mapData => {
          searchCache.documentsMap = mapData; // Cache the docs map
          return mapData;
        });
    }
    
    // Wait for both promises to resolve
    Promise.all([searchCache.indexPromise, searchCache.documentsMapPromise])
      .then(([loadedIndex, loadedMap]) => {
        if (!unmountedRef.current) {
          setLocalIndex(loadedIndex);
          setLocalDocumentsMap(loadedMap);
        }
      })
      .catch(error => {
        console.error('Error loading search data:', error);
      });
  }, []);

  useEffect(() => {
    unmountedRef.current = false;
    loadSearchData();
    
    // Auto-focus for popover
    if (isPopover && searchRef.current) {
      searchRef.current.focus();
    }
    
    // Handle clicking outside to close popover
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
        if (isPopover) onClose();
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      unmountedRef.current = true;
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopover, onClose, loadSearchData])

  const search = (event) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    setShowDropdown(true);
    
    if (localIndex && localDocumentsMap && searchQuery.trim()) {
      try {
        // Simple wildcard search
        const searchString = `${searchQuery}*`;
        
        const searchResults = localIndex
          .search(searchString)
          .map(({ ref }) => localDocumentsMap[ref])
          .slice(0, 8); // Limit to 8 results
          
        setResults(searchResults);
      } catch (error) {
        console.error('Search error:', error);
        setResults([]);
      }
    } else {
      setResults([]);
    }
  }

  // Memoize the search icon to prevent unnecessary re-renders
  const SearchIcon = React.memo(() => (
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5 text-gray-400"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        />
      </svg>
    </div>
  ));
  
  // Handle focus event without causing re-renders
  const handleFocus = useCallback(() => {
    setShowDropdown(true);
  }, []);
  
  // Handle result click
  const handleResultClick = useCallback(() => {
    setShowDropdown(false);
    if (isPopover) onClose();
  }, [isPopover, onClose]);

  return (
    <div 
      className={`${className} relative`}
      ref={searchRef}
    >
      <div className="relative">
        <SearchIcon />
        <input
          type="text"
          value={query}
          placeholder="Search support..."
          className={`pl-10 pr-4 py-2 rounded-lg w-full outline-none border bg-white ${isPopover ? 'border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200' : ''}`}
          onChange={search}
          onFocus={handleFocus}
        />
      </div>
      
      {showDropdown && results.length > 0 && (
        <div
          className={`absolute z-[100] w-full bg-white shadow-xl rounded-lg mt-1 ${isPopover ? 'max-h-[400px]' : 'max-h-80'} overflow-auto border border-gray-200`}
          style={{ 
            width: "100%", 
            backgroundColor: "white", 
            backdropFilter: "none" 
          }}
        >
          <div className="bg-white w-full h-full">
            {results.map((result, index) => (
              <Link to={result.slug} key={index} onClick={handleResultClick}>
                <div className="p-3 hover:bg-gray-50 cursor-pointer border-b border-gray-100 bg-white">
                  <p className="font-medium text-gray-800">{result.page_title}</p>
                  {result.description && (
                    <p className="text-sm text-gray-500 truncate">{result.description}</p>
                  )}
                </div>
              </Link>
            ))}
            <div className="p-2 text-center text-sm text-gray-500 bg-gray-50">
              Press Enter to see all results
            </div>
          </div>
        </div>
      )}
      
      {showDropdown && results.length === 0 && query.trim() !== '' && (
        <div 
          className="absolute z-[100] w-full bg-white shadow-xl rounded-lg mt-1 border border-gray-200"
          style={{ 
            width: "100%", 
            backgroundColor: "white",
            backdropFilter: "none"
          }}
        >
          <div className="p-4 text-center text-gray-500 bg-white">
            No results found for "{query}"
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchComponent
